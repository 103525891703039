/**
 * Initialize cart.
 *
 * Copy values from source flow
 *
 * In case there are no passengers, products or offers,
 * the navigation is aborted.
 */
export default defineNuxtRouteMiddleware(() => {
    const flowStore = useFlowStore();
    const cartStore = useCartStore();

    // Currently, if there is an active "flow", copy products (with offers) and passengers from there
    // In the future, other sources might be added that the cart can be initialized from
    if (flowStore.productConfig) {
        const shopStore = useShopStore();
        const passengerStore = usePassengerStore();
        cartStore.products = deepClone(shopStore.products);
        cartStore.passengers = deepClone(passengerStore.passengers);
        cartStore.lastProductConfig = flowStore.productConfig;
    }

    // in case there are no passengers, products or offers, abort the navigation
    if (
        !cartStore.passengers.length
        || !Object.keys(cartStore.products).length
        || !Object.keys(cartStore.offers).length
    ) {
        return navigateTo(getFirstFlowRoute(flowStore.productConfig?.steps));
    }
});
